<template>
  <div class="about">
    <about-banner />
    <!-- about__info -->
    <div class="about__info">
      <div class="container">
        <div class="about__info-inner">
          <div
            class="about__info-content"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <p class="about__info-text">
              {{ $t("aboutText") }}
            </p>
            <div class="about__info-box">
              <div class="about__info-item">
                <h5 class="about__info-item--title">
                  {{ $t("aboutItemTitle_1") }}
                </h5>
                <p class="about__info-item--text">
                  {{ $t("aboutItemText_1") }}
                </p>
              </div>
              <div class="about__info-item">
                <h5 class="about__info-item--title">
                  {{ $t("aboutItemTitle_2") }}
                </h5>
                <p class="about__info-item--text">
                  {{ $t("aboutItemText_2") }}
                </p>
              </div>
              <div class="about__info-item">
                <h5 class="about__info-item--title">
                  {{ $t("aboutItemTitle_3") }}
                </h5>
                <p class="about__info-item--text">
                  {{ $t("aboutItemText_3") }}
                </p>
              </div>
              <div class="about__info-item">
                <h5 class="about__info-item--title">
                  {{ $t("aboutItemTitle_4") }}
                </h5>
                <p class="about__info-item--text">
                  {{ $t("aboutItemText_4") }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="about__info-images"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div class="about__info-col about__info-left">
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img1.jpg"
                alt=""
              />
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img2.jpg"
                alt=""
              />
            </div>
            <div class="about__info-col about__info-right">
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img3.jpg"
                alt=""
              />
              <img
                class="about__info-img"
                src="@/assets/img/about/about-info-img4.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about__formula -->
    <div class="about__formula">
      <div class="container">
        <div class="about__formula-box">
          <div
            class="about__formula-title about__title"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <div v-html="$t('formulaSuccess')"></div>
          </div>
          <div
            class="about__formula-inner"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--timer">
                {{ $t("formulaSuccessItemTitle_2") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_2") }}
              </p>
            </div>
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--heart">
                {{ $t("formulaSuccessItemTitle_1") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_1") }}
              </p>
            </div>
            <div class="about__formula-item">
              <h6 class="about__formula-item--title about__formula-item--like">
                {{ $t("formulaSuccessItemTitle_3") }}
              </h6>
              <p class="about__formula-item--text">
                {{ $t("formulaSuccessItemText_3") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about__service -->
    <div class="about__service">
      <div class="container">
        <div class="about__service-inner about__service-top">
          <img
            src="@/assets/img/about/service.png"
            alt="image"
            data-aos="fade-right"
            data-aos-duration="800"
          />
          <div
            class="about__service-content"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <h5 class="about__service-title about__title">
              <div v-html="$t('greatService_1')"></div>
            </h5>
            <p class="about__service-text">
              {{ $t("greatServiceItem_1_Text_1") }}
            </p>
            <p class="about__service-text">
              {{ $t("greatServiceItem_1_Text_2") }}
            </p>
          </div>
        </div>

        <div class="about__service-inner about__service-bottom">
          <div
            class="about__service-content"
            data-aos="fade-up-right"
            data-aos-duration="800"
          >
            <h5 class="about__service-title about__title">
              <div v-html="$t('greatService_2')"></div>
            </h5>
            <p class="about__service-text">
              {{ $t("greatServiceItem_2_Text_1") }}
            </p>
          </div>
          <img
            src="@/assets/img/about/quality.png"
            alt="image"
            data-aos="fade-up-left"
          />
        </div>
      </div>
    </div>

    <!-- about__numbers -->
    <div class="about__numbers">
      <div class="container">
        <h2
          class="about__numbers-title"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <span> {{ $t("aboutNumbersTitle") }}</span>
          {{ $t("aboutNumbersSpan") }}
        </h2>
        <div class="about__slider">
          <swiper ref="swiperRef" class="" :options="swiperOption">
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-title">
                  {{ $t("aboutNumbersItem_1_title") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("aboutNumbersItem_1_text") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-title">
                  {{ $t("aboutNumbersItem_2_title") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("aboutNumbersItem_2_text") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-title">
                  {{ $t("aboutNumbersItem_3_title") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("aboutNumbersItem_3_text") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="about__slide"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                <div class="about__slide-title">
                  {{ $t("aboutNumbersItem_4_title") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("aboutNumbersItem_4_text") }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="about__slider-nav">
          <button class="about__slide-prev about__slide-btn" @click="prevSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
          <button class="about__slide-next about__slide-btn" @click="nextSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- about__formats -->
    <div class="about__formats">
      <div class="container">
        <h1
          class="about__formats-title about__title"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          {{ $t("storeFormats_1_Title") }}
          <span> {{ $t("storeFormats_Span") }}</span>
          {{ $t("storeFormats_2_Title") }}
        </h1>
        <div class="about__formats-inner">
          <div
            class="about__formats-item about__formats-hyper"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <img
              class="about__formats-img"
              src="@/assets/img/about/magnum.svg"
              alt=""
            />
            <div class="about__formats-text">
              {{ $t("magnumItem_1_text") }}
            </div>
          </div>
          <div
            class="about__formats-item about__formats-super"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <img
              class="about__formats-img"
              src="@/assets/img/about/magnum-super.svg"
              alt=""
            />
            <div class="about__formats-text">
              {{ $t("magnumItem_2_text") }}
            </div>
          </div>
          <div
            class="about__formats-item about__formats-express"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <img
              class="about__formats-img"
              src="@/assets/img/about/magnum-express.svg"
              alt=""
            />
            <div class="about__formats-text">
              {{ $t("magnumItem_4_text") }}
            </div>
          </div>
          <div
            class="about__formats-item about__formats-daily"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <img
              class="about__formats-img"
              src="@/assets/img/about/magnum-daily.svg"
              alt=""
            />
            <div class="about__formats-text">
              {{ $t("magnumItem_3_text") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about__3d">
      <div class="container">
        <h4 class="about__3d-title about__title">
          {{ $t("3Dtour") }}
        </h4>
        <button
          class="about__3d-close"
          :class="{ 'is-full': full }"
          @click="closeFull"
        ></button>
        <iframe
          ref="iframe3D"
          class="about__3d-iframe"
          :class="{ 'is-full': full }"
          src="https://vr.mir3d.kz/MagnumDaily/"
          scrolling="no"
          allowfullscreen="allowfullscreen"
        ></iframe>
        <button @click="showFull" class="about__3d-btn">Посмотреть</button>
      </div>
    </div>

    <!-- about__own -->
    <div class="about__own" data-aos="zoom-in" data-aos-duration="800">
      <div class="container">
        <div class="about__own-item">
          <div class="about__own-title about__title">
            <span> {{ $t("aboutProductionTitle") }}</span>
            {{ $t("aboutProductionSpan") }}
          </div>
          <p class="about__own-text">
            {{ $t("aboutProductionText") }}
          </p>
        </div>
      </div>
    </div>

    <!-- about__image -->
    <div class="about__image" data-aos="zoom-out" data-aos-duration="800">
      <img src="@/assets/img/about/about-image.jpg" alt="image" />
    </div>

    <!-- about own -->
    <div class="about__marks">
      <div class="container">
        <div
          class="about__marks-descr"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <h2 class="about__marks-title">
            <span> {{ $t("tradeMarksSpan") }}</span> <br />
            {{ $t("tradeMarksTitle") }}
          </h2>
          <p class="about__marks-text">
            {{ $t("tradeMarksText") }}
          </p>
        </div>
        <div
          class="about__marks-img"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          <img src="@/assets/img/about/om.png" alt="image" />
        </div>
      </div>
    </div>

    <!-- about__loyalty -->
    <div class="about__loyalty">
      <div class="container">
        <div class="about__loyalty-inner">
          <img
            src="@/assets/img/about/about-loyalty.png"
            alt=""
            data-aos-duration="800"
            data-aos="fade-right"
          />
          <div
            class="about__loyalty-content"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div
              class="about__loyalty-title about__title"
              v-html="$t('aboutLoyaltyProgramTitle')"
            ></div>
            <div class="about__loyalty-item">
              <h6 class="about__loyalty-item-title">
                {{ $t("aboutLoyaltyProgramItem_1_Title") }}
              </h6>
              <p class="about__loyalty-item-text">
                {{ $t("aboutLoyaltyProgramItem_1_Text") }}
              </p>
            </div>
            <div class="about__loyalty-item">
              <h6 class="about__loyalty-item-title">
                {{ $t("aboutLoyaltyProgramItem_2_Title") }}
              </h6>
              <p class="about__loyalty-item-text">
                {{ $t("aboutLoyaltyProgramItem_2_Text") }}
              </p>
              <br />
              <p class="about__loyalty-item-text">
                {{ $t("aboutLoyaltyProgramItem_3_Text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BannerSvg from "./bannerSvg.vue";
// import BannerSvg2 from "./bannerSvg2.vue";
export default {
  name: "PageAbout",
  components: {
    // BannerSvg,
    // BannerSvg2,
    AboutBanner: () => import("@/components/about/AboutBanner.vue"),
  },
  data() {
    return {
      msg: "",
      swiperOption: {
        sliderTo: 1,
        spaceBetween: 0,
        loop: true,
        // responsive: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: "auto",
          },
          768: {
            slidesPerView: "auto",
          },
          500: {
            slidesPerView: 2,
          },
          420: {
            slidesPerView: 1,
          },
        },
      },

      full: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
    showFull() {
      this.full = true;
      document.querySelector("body").classList.add("no-scroll");
    },
    closeFull() {
      this.full = false;
      document.querySelector("body").classList.remove("no-scroll");
    },
  },
};
</script>

<style>
.about__intro-banner-mobile {
  display: none;
}
@media (max-width: 800px) {
  .about__intro-img--stocks {
    top: -61%;
  }
}
@media screen and (max-width: 500px) {
  .about__intro-banner {
    display: none;
  }
  .about__intro-banner-mobile {
    display: block;
    width: 100%;
  }
}
</style>
